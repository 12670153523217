<template>
  <div class="DisassemblyRecord">

    <Head out='receiving' :title="'开始拆解'" :isPopup="false" @closePopup="$emit('closePopup')" :isShowOut="false" />


    <div class="content">
      
      
      <van-form :style="{ marginTop: '40px',padding:'0 30px'}">
        <van-button class="van-button-p" type="danger" @click="defaultDismantleData(0)" size="large"
          :style="{ marginTop: '15px' }">载入标准拆解物</van-button>
      </van-form>

      <van-form :style="{ marginTop: '20px' }">
        <van-cell  title="开始拆解日期:" :value="dismantleStartTime" @click="showDatePicker = true" />
      <van-calendar v-model="showDatePicker" @confirm="onConfirmDate" :min-date="new Date(2018,1,1)"/>
    </van-form>
      <hr>
      <div :style="{ border: '2px solid black', borderRadius: '10px', margin: '15px' }">
        <div :style="{ textAlign: 'center', margin: '20px 0', fontWeight: 800, fontSize: '20px' }">拆车件信息</div>
        <van-form class="forms" v-for="(item, index) in list_parts" :key="index"
          :style="{ border: '1px solid green', borderRadius: '10px', margin: '15px' }">
          <van-field readonly clickable v-model="CarTypePickerCtrl[index].carTypeName" name="picker" :label="'配件分类：'"
            :placeholder="'点击选择配件分类'" @click="showTypePicker(index)" />
          <van-popup v-model="CarTypePickerCtrl[index].isShow" position="bottom" :close-on-click-overlay="false">
            <van-picker show-toolbar :columns="columns" :default-index="CarTypePickerCtrl[index].defaultIndex"
              value-key="name" @confirm="onConfirm(index, $event)" @cancel="closeTypePicker(index)" />
          </van-popup>
          <van-field v-model="item.patrsName" label="配件名称" placeholder="请输入配件名称" />
          <van-field v-model="item.num" label="配件数量" placeholder="请输入配件数量" />
          <van-field v-model="item.weight" label="配件重量" placeholder="请输入配件重量" />
          <van-field v-model="item.color" label="配件颜色" placeholder="请输入配件颜色" />

          <van-field name="radio" label="是否属于五大总成">
            <template #input>
              <van-radio-group v-model="item.five_big" direction="horizontal">
                <van-radio :name="1">属于</van-radio>
                <van-radio :name="2">不属于</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div v-if="!isWechat">
            <van-field name="uploader" label="配件图片">
              <template #input>
                <van-uploader v-model="item.imgs" :after-read="uploadershang(index, 'restsImg')"
                  :before-read="beforeRead" multiple :before-delete="deleteimg2"></van-uploader>
              </template>

            </van-field>
          </div>

          <div class="upimg" v-else>
            <p>配件图片</p>
            <p>
              <span class="imgbox" v-for="(item, index) in item.img_boxs" :key="index">
                <van-image class="vanImage" width="80" height="80" :src="item" @click="bigImg(item)">
                  <van-icon class="iconX" name="close" @click="delImgHan('huiyongPhoto', index)" />
                </van-image>
              </span>
              <span class="uploaderImg" @click="WeChooseImage(9, 'huiyongPhoto', index)">
                <van-icon class="iconz" name="plus" />
              </span>
            </p>
          </div>
          <van-button class="van-button-p" type="warning" @click="del(index)" size="large">删除当前配件</van-button>
        </van-form>
        <van-form :style="{ margin: '10px' ,textAlign:'center'}">
          <van-button class="van-button-p" type="info" @click="zengjiaData" size="small" :style="{borderRadius:'5px',backgroundColor:'red',padding:'10px 20px'}">增加配件</van-button>
        </van-form>
      </div>

      <div :style="{ border: '2px solid black', borderRadius: '10px', margin: '15px', paddingBottom: '10px' }">
        <div :style="{ textAlign: 'center', margin: '20px 0', fontWeight: 800, fontSize: '20px' }">大宗拆解物信息</div>
        <van-field v-for="item in list_a" :key="item.dangerClassId" v-model="item.weight" :label="item.name"
          placeholder="" label-width="50%" class="fieldInput">
          <template #button>
            <span>Kg</span>
          </template>
        </van-field>
      </div>


      <div :style="{ border: '2px solid black', borderRadius: '10px', margin: '15px', paddingBottom: '10px' }">
        <div :style="{ textAlign: 'center', margin: '20px 0', fontWeight: 800, fontSize: '20px' }">危废拆解物信息</div>
        <van-field v-for="item in list_b" :key="item.dangerClassId" v-model="item.weight" :label="item.name"
          placeholder="" label-width="50%" class="fieldInput">
          <template #button>
            <span>Kg</span>
          </template>
        </van-field>
      </div>

      <van-form :style="{ margin: '30px' }">
        <van-button class="van-button-p" type="info" @click="finish()" size="large">提交</van-button>
      </van-form>

    
    </div>


    <van-popup v-model="isAddShow" :closeable="false" :close-on-click-overlay="false" position="bottom" :style="{
      height: '50%',
    }">
      <defaultDismantleAll ref="defaultDismantleAllRef" @closePopup="closeDefaultPartPopup" @addAll="addAll" />

    </van-popup>


    <van-popup v-model="shows_b" round  class="popup" :close-on-click-overlay="false">
		  	<div class="Dialog" v-show="shows_b">
          <div class="title">系统提示</div>
          <div class="mains">点击拆解后不可再次编辑</div>
          <div class="btnList">
            <span style="border-right: 1px solid" @click="finishEnd()">取消</span>
            <span @click="goDistribution('2')">确定拆解完成</span>
          </div>
			</div>
	  </van-popup>

    <van-popup v-model="shows" round :close-on-click-overlay="false">
		   <div class="Dialog" v-show="shows_a">
				<div class="title">系统提示</div>
				<div class="mains">请选择保存类型</div>
				<div class="btnList">
				<span @click="shows = false">取消</span>
				<span
					style="border-right: 0.9px solid; border-left: 0.9px solid"
					@click="goSave()"
					>拆解完成</span
				>
				<span @click="goDistribution('1')">仅保存</span>
				</div>
			</div>
			
	  </van-popup>
  </div>
</template>

<script>
import Head from '@/components/Head'
import defaultDismantleAll from '@/components/defaultDismantle/all.vue'

import { reusablePartsTask, recordsFind } from '@/api/gong'
import { Toast, Dialog } from 'vant'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import { formatDate,formatDates } from '@/utils/formatDate'
import { deleteCookie } from '../../utils/env'
export default {
  components: {
    Head, defaultDismantleAll
  },
  data() {
    return {
      show: false,
      shows: false,
      shows_a: false,
      shows_b: false,
      showDatePicker:false,
      dismantleDataType: 0,
      isAddShow: false,
      imageFile: [],
      WXimg: [],
      username: "",
      password: '',
      toast: null,
      wximagelocalids: [],
      columns: [],
      CarTypePickerCtrl: [],
      islable: "",//判断上川的什么类型照片进行识别
      idsIndex: null,
      list_parts: [
        // {
        //     patrsName:"",//配件名称
        //     num:"",//配件数量
        //     color:"",//配件颜色
        //     carAccessoriesId:"",//配件分类
        //     five_big:"",//五大总成
        //     weight:"",//重量
        //     img_boxs:[],//照片
        //     imgs:[],
        // },
      ],
      list_a: [],
      list_b: [],
      dismantleStartTime:formatDates(new Date() ,'yyyy-M-d'),
    }
  },
  mounted() {
    //this.init()
  },
  async created() {
    await this.$http.post("/index.php/index/Simply", { type: "carAccessories" }).then((res) => {
      this.columns = res.data.data;
    });
    //大宗拆解物分类
    await this.$http.post("/index.php/index/Simply", { type: "bulkDismantlingClass" }).then((res) => {
      // //console.log(res.data.data);

      this.bulkDismantlingClass = res.data.data;

      let arr = []
      let obj = {}
      this.bulkDismantlingClass.forEach(element => {
        obj = { bulkDismantlingClassId: element.id, name: element.name, weight: '' }
        arr.push(obj)
      })
      //  //console.log(arr)
      this.list_a = arr
    });
    //废危解物分类
    await this.$http
      .post("/index.php/index/Simply", { type: "dangerClass" })
      .then((res) => {

        this.dangerClass = res.data.data;
        let arr = []
        let obj = {}
        this.dangerClass.forEach(element => {
          obj = { dangerClassId: element.id, name: element.name, weight: '' }
          arr.push(obj)
        })
        this.list_b = arr
      });

    await this.getData()

  },
  watch: {
    wximagelocalids: {
      handler(newValue, oldValue) {
        if (newValue.length > 0) {
          this.toast = Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "上传中..."
          });
          this.wxUpload(newValue[0])
        } else {
          this.toast.clear();
        }
      },
      deep: true,
    }
  },
  methods: {
    finish() {
	
      this.shows = true;
      this.shows_a = true;
    },

    goSave() {
      // this.shows = false;
      this.shows_a = false;
      this.shows_b = true;
    },

    //第二个取消
    finishEnd() {
      this.shows = false;
      this.shows_b = false;
    },
    dateFormat(date){
      return formatDates(date,'yyyy-M-d')
    },
    onConfirmDate(date) {
      this.showDatePicker = false;
      this.dismantleStartTime = this.dateFormat(date);
    },
    getData() {
      // 获取保存的拆车件
      let data = encipherMent(JSON.stringify({ id: this.$route.query.ids, }))
      let param = new URLSearchParams();
      param.append("value", data);
      this.$http.post("/index.php/index/Dismantled_Parts_Save/_get", param).then((res) => {
        let result = {}
        if (res.data.code == -1 || res.data.code == 9) {
          result = res.data
        } else {
          result = JSON.parse(encryptDecode(res.data))
        }
        console.log(result)
        if (result.code == 0) {
          if (result.data != '') {
            this.addParts(result.data.dismantledCarParts)
            this.addBulk(result.data.bulkWarehousing)
            this.addWaste(result.data.dangerWarehousing)
            this.dismantleStartTime=result.data.dismantleStartTime
            
          } else if (result.code == '-1') {
            this.$toast.fail(result.msg)
            this.$router.push('login')
            deleteCookie('token')
          } else {
            //this.$toast.fail(result.msg)
          }
        }
      })
    },
    addBulk(bulk) {
      console.log("addBulk",bulk)
      console.log("addBulk",this.list_a)
      console.log(this.list_a)
      this.list_a.forEach(
        (item, index) => {
          let arr = bulk.filter(function (data) {
            if(data.bulkDismantlingClassId){
              return data.bulkDismantlingClassId===item.bulkDismantlingClassId
            }
            if(data.bulkdismantlingclass_id){
              return data.bulkdismantlingclass_id === item.bulkDismantlingClassId
            }
          });
          if (typeof (arr[0]) != 'undefined') {
            item.weight = arr[0].weight
          }else{
            item.weight = ''
          }
        }
      )
    },
    addWaste(waste) {
      console.log("addBulk",waste)
      console.log("addBulk",this.list_b)
      this.list_b.forEach(
        (item, index) => {
          let arr = waste.filter(function (data) {
            if(data.dangerClassId){
              return data.dangerClassId===item.dangerClassId
            }
            if(data.dangerclass_id){
              return data.dangerclass_id === item.dangerClassId
            }
          });
          if (typeof (arr[0]) != 'undefined') {
            item.weight = arr[0].weight
          }else{
            item.weight = ''
          }
        }
      )
    },
    addParts(parts) {
      console.log("parts", parts)
      let that = this
      this.CarTypePickerCtrl = [];
      this.list_parts = [];
      parts.forEach((item) => {
        that.zengjiaData(item);
      })
    },
    
    addAll(data) {

      this.closeDefaultPartPopup();
      this.addParts(data.parts);
      this.addBulk(data.bulk);
      this.addWaste(data.waste);
    },
    showTypePicker(index) {
      this.CarTypePickerCtrl.forEach((item, idx) => {
        this.CarTypePickerCtrl[idx].isShow = false
      })
      this.CarTypePickerCtrl[index].isShow = true
      console.log(index, "开启")
      console.log(this.CarTypePickerCtrl)
    },
    closeTypePicker(index) {
      this.CarTypePickerCtrl[index].isShow = false
      console.log(index, "关闭")
      console.log(this.CarTypePickerCtrl)
    },

    
    //新增列表
    zengjiaData(data = {}) {
      let dataBasic = {
        name: "",
        carPartsType: "",
        carAccessoriesId: "",//配件分类ID
        five_big: "",
        weight: "",
        account: "",
      }
      data = Object.assign(dataBasic, data)

      let obj = {
        patrsName: data.name ? data.name : data.patrsName,//配件名称
        num: data.account ? data.account : data.num,//配件数量
        color: data.color ? data.color : '',//配件颜色
        carAccessoriesId: data.caraccessories_id ? data.caraccessories_id : data.carAccessoriesId,//配件分类ID
        five_big: data.five_big,//五大总成
        weight: data.weight,//重量
        img_boxs: data.img_boxs ? data.img_boxs : [],//照片
      }
      this.showData(obj)
    },
    showData(obj) {
      console.log("obj", obj)


      obj.imgs = []
      if (obj.img_boxs.length > 0) {
        obj.img_boxs.forEach(k => {
          obj.imgs.push({ url: k })
        })
      }

      let CarTypePickerCtrl = {}
      CarTypePickerCtrl.isShow = false

      let arr = this.columns.filter(function (data) {
        return data.id === obj.carAccessoriesId;
      });

      if (typeof (arr[0]) != 'undefined') {
        CarTypePickerCtrl.defaultIndex = this.columns.indexOf(arr[0])
      } else {
        CarTypePickerCtrl.defaultIndex = -1
      }

      console.log(CarTypePickerCtrl.defaultIndex)
      if (CarTypePickerCtrl.defaultIndex > -1) {
        CarTypePickerCtrl.carTypeName = this.columns[CarTypePickerCtrl.defaultIndex].name
      }

      console.log(CarTypePickerCtrl.carTypeName)
      this.CarTypePickerCtrl.push(CarTypePickerCtrl)
      this.list_parts.push(obj)
      console.log(this.CarTypePickerCtrl)
    },
    closeDefaultPartPopup() {
      this.isAddShow = false
    },
    defaultDismantleData(type) {
      this.dismantleDataType = type
      this.isAddShow = true
    },
    onConfirm(index, e) {
      this.list_parts[index].carAccessoriesId = e.id
      this.CarTypePickerCtrl[index].carTypeName = e.name
      this.closeTypePicker(index)
    },

    async find(id, type) {
      let that = this
      const { data } = await recordsFind({ id, type }).then(res => res)
      if (data.data.val) {
        that.CarTypePickerCtrl = [];
        that.list_parts = [];
        data.data.val.forEach((item, index) => {
          that.showData(item)
        })
      }
    },
    //删除
    del(i) {
      this.CarTypePickerCtrl.splice(i, 1)
      this.list_parts.splice(i, 1)
    },
    //确定
    Submit(num) {

      if (num == 0) {
        this.save(num)
      } else {
        Dialog.confirm({
          title: '提示',
          message: '提交之后不可修改！',
        })
          .then(async () => {
            this.save(num)
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    save(){

    },
    goDistribution(type) {
      let data = {
        end_of_lifeVehiclesId: this.$route.query.id,
        id: this.$route.query.ids,
        type: parseInt(type),
        bulkWarehousing: this.list_a,
        dangerWarehousing: this.list_b,
        dismantledCarParts: this.list_parts,
        dismantleStartTime: this.dismantleStartTime,

      }
      /* console.log(JSON.stringify(data))
      return */
      data = encipherMent(JSON.stringify(data))
      let param = new URLSearchParams();
      param.append("value", data);
      this.$http.post("/index.php/index/Dismantled_Car_Parts/dismantleRecord", param).then((res) => {
        let result = {}
        if (res.data.code == -1 || res.data.code == 9) {
          result = res.data
        } else {
          result = JSON.parse(encryptDecode(res.data))
        }
        if (result.code == "0") {
          //console.log(a)
          if (type == '1') {
            this.$toast.success("保存成功");
            this.$router.go(-1)
          } else {
            // alert("1111")
            this.$toast.success(result.msg);
            this.$router.go(-1)
          }
        } else {
          this.$toast.fail(result.msg);
          this.shows_b = false
        }
      });

    },
    async save(num) {
      let obj = {
        dismantledCarParts: this.list_parts,
        type: num,
        id: this.$route.query.id
      }
      const { data } = await reusablePartsTask(obj).then(res => res)
      this.$toast.success(data.msg)
      this.$emit('closePopup')
    },


    beforeRead(file) {
      if (file instanceof Array) {
        for (let i = 0; i < file.length; i++) {
          if ((file[i].type !== 'image/jpeg') && (file[i].type !== 'image/png')) {
            Toast('请上传 jpg/png 格式图片');
            return false;
          }
          return true;
        }
      } else {
        if ((file.type !== 'image/jpeg') && (file.type !== 'image/png')) {
          Toast('请上传 jpg/png 格式图片');
          return false;
        }
        return true;
      }
    },

    // 上传照片
    uploadershang(i, name) {
      return file => {
        file.status = "uploading";
        file.message = "上传中...";
        if (file instanceof Array) {
          file.forEach(item => {
            this.upImages(i, name, item)
          })
        } else {
          this.upImages(i, name, file)
        }
      }
    },
    upImages(i, name, file) {
      file.status = "uploading";
      file.message = "上传中...";
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      this.$http.post("/index.php/index/upload/index", params)
        .then((res) => {
          // toast.clear()//清除加载效果
          if (res.data.code == 0) {
            file.status = "done";
            console.log(file)
            // this.list_parts[i].imgs.forEach(item=>{
            //     if(item.file.name == file.file.name){
            //         item.content = res.data.data
            //     }
            // })
            file.content = res.data.data
            this.list_parts[i].img_boxs.push(res.data.data)

          } else if (res.data.code == -1) {
            this.islogout()
          } else {
            file.status = "failed";
            file.message = "上传失败";
            this.$toast.fail(res.data.msg)
          }
        }).catch(() => {
          if (name == 'restsImg') {
            this.list_parts.img_boxs = []
          }
          // toast.clear()//清除加载效果
          file.status = "failed";
          file.message = "上传失败";
          this.$toast.fail('网络开小差了，请稍后重试！')
        })
    }
  },
}
</script>
<style>
.fieldInput input {
  border: 1px solid rgb(126, 124, 124) !important;
  border-radius: 5px;
}

</style>
<style lang="scss" scoped> 
html {
  height: 100%;
  background-color: #f5f5f5;
}
.DisassemblyRecord{
  .van-popup--center{
    width: 7rem !important;
    height: 3.3rem !important;
    // background-color: red;
  }
.Dialog {
  width: 100%;
  height: calc(100% - 20px);
  border: 1px solid #000;
  .title {
    height: 0.85rem;
    text-align: center;
    font-size: 0.38rem;
    line-height: 0.85rem;
    background-color: cadetblue;
    color: white;
  }
  .mains {
    height: 1rem;
    text-align: center;
    font-size: 0.38rem;
    line-height: 1rem;
    margin: 0;
    // border-top: 1px solid;
    // border-bottom: 1px solid;
  }
  .btnList {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid;
    height: 0.95rem;
    span {
      width: 50%;
      display: inline-block;
      text-align: center;
      font-size: 0.38rem;
      line-height: 0.95rem;
      // border: 0.05px solid ;
    }
  }
}

}

.van-cell__value {
  border: 1px solid;
  text-align:center
}
.shadown {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  position: absolute;
  top: 0;
  z-index: 9999;
}
.ClassificationList {
  span {
    font-size: 0.38rem;
    width: 2rem;
    display: inline-block;
    height: 0.5rem;
    text-align: center;
  }
  .select {
    width: 59%;
    margin-left: 0.5rem;
    height: 0.6rem;
    border-radius: 0px;
  }
  input {
    width: 58%;
    margin-left: 0.5rem;
    
    border-radius: 0px;
    border: 1px solid;
    font-size: 0.38rem;
  }
  div{
	  margin: 6px 0;
  }
  div:nth(3) {
    input {
      width: 30%;
    }
  }
}
.header {
  padding: 0 20px;
  background-color: cadetblue;
  height: 1rem;
  line-height: 1rem;
  color: white;
  font-size: 0.4rem;
  display: flex;
   position: fixed;
   top: 0;
   width: calc(100% - 40px);
   z-index: 9;
  justify-content: space-between;
  span {
    display: inline-block;
  }
  span:nth-child(1) {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/u39.png);
    background-size: 100%;
    margin-top: 8px;
  }
}
.first {
  width: 100%;
  height: 0.5rem;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  span {
    display: inline-block;
    font-size: 0.4rem;
    width: 70%;
    text-align: left;
  }
  input {
    display: inline-block;
    width: 30%;
    height: 0.4rem;
    border-radius: 0px;
    font-size: 0.4rem;
    -webkit-appearance: none; /*去除系统默认的样式*/
  }
}
.firsts {
  width: 100%;
  height: 0.5rem;
  display: flex;
  justify-content: space-between;
  margin-top: 0.3rem;
  span {
    display: inline-block;
    font-size: 0.4rem;
    width: 20%;
    text-align: center;
  }

  input {
    display: inline-block;
    width: 20%;
    height: 0.4rem;
    border-radius: 0px;
    font-size: 0.4rem;
    -webkit-appearance: none; /*去除系统默认的样式*/
  }
}
.table_two {
}
.van-popup {
	padding: 20px;
}
.box{
  margin-top: 1.5rem;
}
.van-popup--bottom{
  width: calc(100% - 40px);
}
.assembly{
  display: flex;
  height: 30px;
  margin-top: 10px;
  .group-radio{
    margin-left: 20px;
  }
}
   .uploaderImg{
        display: inline-block !important;
        width: 80px !important;
        height: 80px !important;
        border: 1px solid #eee !important;
        position: relative !important;
        .iconz{
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}

</style>