import axios from 'axios'
import { Toast } from 'vant';
import router from '@/router/index'
import {deleteCookie} from '@/utils/env'
// create an axios instance
const service = axios.create({ 
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
//   timeout: 5000 // request timeout
})

service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(new Error(error))
  }
)


// response interceptor响应拦截器
service.interceptors.response.use(

  response => {
    const { data, meta } = response.data

    // if the custom code is not 20000, it is judged as an error.
    if(response.data.code == 0){
      return response
    }else if(response.data.code == -1){
      console.log(response)
      Toast(response.data.msg);
      router.push('/login')
      deleteCookie('token')
    }else{
        Toast(response.data.msg);
      return Promise.reject(response.data.msg)

    }
    
  },
  error => {
    //console.log('err' + error) // for debug
    Toast.fail('请求失败');
    // error.response && ElMessage.error(error.response.data)
    return Promise.reject(new Error(error.response.data))
  }
)

export default service
