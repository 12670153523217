export function formatDate(val) {
  var date = new Date(Number(val)) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = date.getDate() + ' '
  var h = date.getHours() + ':'
  var m = date.getMinutes() + ':'
  var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds())
  return Y + M + D + h + m + s
}

export function formatDates(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }
  }
  return fmt
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}


export function getNearly7Day() {
  let days = []
  var date = new Date()
  for (let i = 0; i <= 24 * 6; i += 24) {
    //今天加上前6天
    let dateItem = new Date(date.getTime() - i * 60 * 60 * 1000) //使用当天时间戳减去以前的时间毫秒（小时*分*秒*毫秒）
    let y = dateItem.getFullYear() //获取年份
    let m = dateItem.getMonth() + 1 //获取月份js月份从0开始，需要+1
    let d = dateItem.getDate() //获取日期
    m = addDate0(m) //给为单数的月份补零
    d = addDate0(d) //给为单数的日期补零
    let valueItem = y + '-' + m + '-' + d //组合
    days.push(valueItem) //添加至数组
}
  //给日期加0
function addDate0(time) {
    if (time.toString().length == 1) {
      time = '0' + time.toString()
    }
    return time
  }
  return days
}
